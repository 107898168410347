@import "../../assets/styles/ez-responsive";

.faq-page {
    .ezBannerContainer {
        position: relative;
        width: 100%;
    }

    .ezStaticBannerImg {
        width: 100%;
    }

    .faq-section {
        background-color: #ffffff;
        border-style: solid;
        border-width: 1px;
        border-color: #e0e0e0;
        padding: 8px;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .faq-question {
        font-size: 24px;
        font-weight: bold;
    }

    .faq-answer {
        padding-left: 8px;
        line-height: 20px;
    }

    .ezClockerBasicContentContainer {
        background-color: rgba(255, 255, 255, 0.5);
        margin: 0;
        padding: 20px;
        border-style: none;
        border-width: 0;
        border-top-color: #000000;
        border-top-width: 1px;
        border-top-style: solid;
        box-shadow: 0 -2px 4px 0 rgba(109, 110, 112, 0.5);

    }

    .headerButton {
        vertical-align: middle;
        font-size: 16px;
        font-weight: bold;
        border-radius: 3px;
    }

    .video-heading{
        font-size: 20px;
    }

    .video-container {
        width: 560px;
        height: 315px;
    }

    .faq-mobile {
        display: none;
        margin-top: 10px;
        margin-bottom: 10px;
    }

}

/*------------------------------------------------------
| @Media for Regular Mobile Devices
--------------------------------------------------------*/
@media only screen and (min-width: $ezMobileMinWidth) and (max-width: $ezMobileMaxWidth) {
    .faq-page {
        .video-container {
            width: 320px;
            height: 200px;
        }

        .faq-desktop {
            display: none;
        }

        .faq-mobile {
            display: block;
        }

        .headerButton {
            vertical-align: middle;
            font-size: 14px;
            font-weight: bold;
            border-radius: 3px;
        }

        .faq-question {
            font-size: 15px;
            font-weight: bold;
        }

        .faq-answer {
            padding-left: 8px;
            line-height: 20px;
            font-size: 13px;
        }

        .video-heading{
            font-size: 13px;
        }

        .pricing-link {
            font-size: 13px;
        }

        .contact-link{
            font-size: 13px;
        }
    }
}