.landing-page {
    color: var(--ezClockerBlack);
    background-color: var(--ezClockerBackground);
    font-family: Roboto, Verdana, sans-serif;
}

/* Extra small devices (phones, 600px and down) */
@media (min-width : 320px) and (max-width: 479.98px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width : 1200px) {}