@import '../../assets/styles/ez-core';
@import '../../assets/styles/ez-responsive';
@import '../../assets/styles/ez-buttons.scss';

.pricingPage {
    background-color: $color_ezClockerWhite;

    h2 {
        font-size: 1.70rem;
    }

    #startPlanText {
        font-size: 1rem;
    }

    #intro-text-col {
        text-align: center;
        padding-bottom: 20px
    }

    #intro-sub-text-col {
        text-align: center;

        .intro-row {
            padding: 40px;
        }
    }

    #try-button-col {
        padding-top: 5px;
    }

    #switch {
        text-align: center;
    }

    #plan-month-price {
        padding-right: 10px;
        color: $color_ezClockerStone;
        text-decoration: line-through;
    }

    #plan-yearly-price {
        color: $color_ezClockerGreen;
    }

    #per-month-text {
        font-size: 1rem;
        padding-right: 5px
    }

    .list-group-item {
        border: 0;
        font-size: 1.2rem;
        background-color: $color_ezClockerOffWhite;
    }

    .plan-name {
        .strike-through {
            text-decoration: line-through;
            margin-right: 10px;
        }
    }

    .stand-out {
        border-color: $color_ezClockerBabyBlue; // !important;
        border-width: 4px;
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); // !important;

        .plan-name {
            padding-left: 40px;
            word-wrap: break-word;
        }
    }

    .stand-out::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 64px 64px 0 0;
        border-color: #3bc6b8 transparent transparent transparent;
        position: absolute;
        left: 0;
        top: 0;
        content: "";
    }

    .stand-out::after {
        font-size: .7rem;
        content: "Most Popular";
        word-wrap: break-word;
        width: 50px;
        color: $color_ezClockerWhite;
        position: absolute;
        left: 2px;
        top: -4px;
        text-shadow: 0 0 2px $color_ezClockerBabyBlue;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .bg-light {
        background-color: $color_ezClockerOffWhite;
    }

    .sect-header {
        text-align: center;
    }

    #plans {
        padding: 5% 0;

        #planRow {
            padding-top: 30px;

            h3 {
                font-size: 1.2rem;
            }

            h5 {
                font-size: 1rem;
            }

            .card {
                color: $color_ezClockerWhite;
                background-color: $color_ezClockerOrange;
                border-color: $color_ezClockerWhite;
                height: 100%;
                margin: 1rem 0;
                border-radius: 0.375rem 0.375rem 0 0;

                .card-header {
                    background-color: $color_ezClockerNavy;
                    padding: 12px;
                    text-align: center;
                    border-top-left-radius: 0.375rem;
                    border-top-right-radius: 0.375rem;

                    .p-6 {
                        padding: 0; // !important;
                    }
                }

                .card-block {
                    padding: 15px 15px 0;
                    background-color: $color_ezClockerOrange;
                    color: $color_ezClockerBlack;
                    font-style: bold;

                    .card-title {
                        padding-top: 10px;
                    }

                    ul {
                        margin-bottom: 0;
                    }

                    li {
                        line-height: 1.5;
                    }
                }
            }
        }

        #sliderRow {
            padding-top: 30px;

            .switch label {
                cursor: pointer;
                display: inline-block;
                margin-bottom: .5rem;
            }

            .switch label input[type=checkbox] {
                opacity: 0;
                width: 0;
                height: 0;
            }

            [type=checkbox]:checked,
            [type=checkbox]:not(:checked) {
                position: absolute;
                pointer-events: none;
            }

            input[type=checkbox],
            input[type=radio] {
                box-sizing: border-box;
                padding: 0;
            }

            button,
            input {
                overflow: visible;
            }

            .switch.blue-white-switch label .lever {
                background-color: #004e76;
                width: 54px;
                height: 34px;
                border-radius: 10em;
            }

            .switch.blue-white-switch label input[type=checkbox]:checked+.lever {
                background-color: $color_ezClockerGreen;
            }

            .switch.round.blue-white-switch label .lever:after {
                width: 26px;
                height: 26px;
                border-radius: 50%;
                left: 4px;
                top: 4px;
            }

            .switch label .lever {
                content: "";
                display: inline-block;
                position: relative;
                background-color: #818181;
                border-radius: .9375rem;
                margin: 0 1rem;
                margin-right: .625rem;
                vertical-align: middle;
                width: 2.5rem;
                height: .9375rem;
                -webkit-transition: background .3s ease;
                -moz-transition: background .3s ease;
                -o-transition: background .3s ease;
                transition: background .3s ease;
            }

            .switch label .lever:after {
                content: "";
                position: absolute;
                display: inline-block;
                background-color: $color_ezClockerWhite;
                border-radius: 1.3125rem;
                left: -.3125rem;
                top: -.1875rem;
                box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0, 0, 0, .4);
                width: 1.3125rem;
                height: 1.3125rem;
                -webkit-transition: left .3s ease, background .3s ease, box-shadow 1s ease;
                -moz-transition: left .3s ease, background .3s ease, box-shadow 1s ease;
                -o-transition: left .3s ease, background .3s ease, box-shadow 1s ease;
                transition: left .3s ease, background .3s ease, box-shadow 1s ease;
            }

            .switch label input[type=checkbox]:checked+.lever:after {
                left: 1.5rem;
            }
        }
    }

    #features {
        padding: 5% 0;
        padding-top: 50px;

        .features-row {
            padding-top: 40px;
            padding-bottom: 30px;

            .col-lg-2 .col-lg-4 {
                line-height: 2;
                text-align: center;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    padding: 0.5rem 1rem;
                    font-size: 1.2rem;
                    text-align: left;
                    display: flex;

                    svg {
                        margin-top: 3px;
                    }

                    .feature-text {
                        padding-left: 10px;
                        font-weight: 500
                    }
                }
            }
        }
    }

    #faq {
        padding-top: 50px;
        padding-bottom: 5%;

        .sect-header {
            margin-bottom: 40px;
        }

        .MuiAccordion-root {
            margin: 5px 0;
            box-shadow: none;
            border: none;

            .MuiButtonBase-root {
                min-height: 35px;
            }

            .MuiAccordionSummary-content {
                margin: 0;

                .accordion-title {
                    color: $color_ezClockerNavy;
                }
            }

            .MuiCollapse-wrapper {
                .MuiAccordionDetails-root {
                    padding: 1rem;
                }

                .MuiTypography-root {
                    line-height: 3;
                }
            }
        }

        #faq-row {
            padding-top: 40px;

            .col-lg-12 {
                line-height: 3;
            }

            .card-header {
                background-color: $color_ezClockerWhite;

                .qheader {
                    text-decoration: none;

                    .rotate-icon {
                        float: right;
                    }
                }
            }

            .card-body {
                background-color: $color_ezClockerWhite;
                color: black;
            }
        }

        #gotmoreq {
            padding-top: 30px;
            padding-bottom: 10px;

            .col-lg-12 {
                text-align: center
            }
        }
    }

    .fa-check {
        color: $color_ezClockerGreen;
    }

    .plan-btns-group {
        margin-top: 30px;
        border-radius: 50px;
        background: var(--ezClockerWhite);
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

        button {
            border-radius: 50px;
            border: none;
            text-transform: capitalize;
            font-weight: 600;
            color: var(--ezClockerBlack);
            padding: 8px 25px;

            &:active,
            &:focus {
                box-shadow: none;
            }

            &.active {
                color: var(--ezClockerWhite);
                background-color: var(--ezClockerOrange);
            }
        }
    }
}

/*------------------------------------------------------
| @Media Small Mobile Devices
--------------------------------------------------------*/
@media only screen and (max-width: $ezSmallMobileMaxWidth) {}

/*------------------------------------------------------
| @Media for Regular Mobile Devices
--------------------------------------------------------*/
@media only screen and (min-width: $ezMobileMinWidth) and (max-width: $ezMobileMaxWidth) {
    .pricingPage {
        #plans {
            #planRow {
                .card {
                    height: auto;

                    .card-block {
                        padding: 15px;

                        ul {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------------------
| @Media for Tablets
--------------------------------------------------------*/
@media only screen and (min-width: $ezTabletMinWidth) and (max-width: $ezTabletMaxWidth) {
    .pricingPage {
        #plans {
            margin-top: 8px;

            #planRow {
                .card {
                    padding-bottom: 25px;
                }
            }
        }
    }
}

/*------------------------------------------------------
| @Media for Laptops
--------------------------------------------------------*/
@media only screen and (min-width: $ezLaptopMinWidth) and (max-width: $ezLaptopMaxWidth) {
    .pricingPage {
        h3 {
            font-size: 1.75rem;
        }

        #startPlanText {
            font-size: 1.5rem;
        }

        #planRow {
            h3 {
                font-size: 1.5rem;
            }
        }
    }
}

/*------------------------------------------------------
| @Media for Desktops
--------------------------------------------------------*/
@media only screen and (min-width: $ezDesktopMinWidth) {}