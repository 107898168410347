@import '../../assets/styles/ez-core';
@import '../../assets/styles/ez-responsive';

.contactPage {
    body {
        font-family: $ezFontFamilyRoboto, $ezFontFamilyVerdana, $ezFontFamilySansSerif;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    h2 {
        font-size: 1.75rem;
    }

    li {
        line-height: 1.5;
    }

    .contact-us-banner {
        background-color: $ezClockerWhite;
        width: 100%;
        display: flex;
    }

    .ez-static-banner {
        width: 100%;
    }

    .section {
        padding-top: 30px;
    }

    .contact-body {
        padding: 5% 0;
        padding-top: 30px;

        .MuiContainer-root {
            padding-top: 5px;
            padding-bottom: 10px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}