.video-section {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;
    background-color: var(--ezClockerNavy);
    border-color: var(--ezClockerOrange);
    border-style: solid;
    border-width: 0px;
    border-top-width: 10px;
    padding: 20px;

    .video-section-title {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        align-items: center;
        justify-items: center;
        color: var(--ezClockerWhite);
        font-weight: bold;
    }

    .video-container {
        width: 560px;
        height: 315px;
    }
}

/* XX-Small devices between 280 and 319.98 */
@media (min-width: 280px) AND (max-width: 319.98) {
    .video-section {
        .video-container {
            width: 320px;
            height: 315px;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media (min-width : 320px) and (max-width: 479.98px) {
    .video-section {
        .video-section-title {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            align-items: center;
            justify-items: center;
            color: var(--ezClockerWhite);
            font-weight: normal;
            font-size: 12px;

            h1 {
                text-align: center;
            }
        }

        .video-container {
            width: 280px;
            height: 138px;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 480px) and (max-width: 767.98) {
    .video-section {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    align-items: center;
    justify-items: center;
    background-color: var(--ezClockerNavy);
    border-color: var(--ezClockerOrange);
    border-style: solid;
    border-width: 0px;
    border-top-width: 10px;
    padding: 20px;

    .video-section-title {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        align-items: center;
        justify-items: center;
        color: var(--ezClockerWhite);
        font-weight: bold;
    }

    .video-container {
        width: 560px;
        height: 315px;
    }
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width : 1200px) {}
