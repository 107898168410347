@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

.page-not-found-wrapper {
    margin: 8px;
    padding: 8px;
    background-color: #E1E1E1;
    color: var(--ezClockerBlack);
    border: 1px solid #6D6E70;
    font-family: 'Roboto', sans-serif;
    font-size: 14pt;


    h1 {
        margin-top: 4px;
        margin-bottom: 4px;
        color: #000;
        font-size: 32pt;
        font-weight: bold;
        font-style: normal;
        font-family: 'Roboto', sans-serif;
    }
    h3 {
        margin-top: 4px;
        margin-bottom: 8px;
        color: #0F4779;
        font-size: 18pt;
        font-weight: bold;
        font-style: normal;
        font-family: 'Roboto', sans-serif;
    }
    small {
        font-size: 11.5px;
    }
    a {
        font-size: 11.5px;
        color: #5A3E8B;
    }
}