/* Default Styling */
.navigation-bar {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    align-content: center;
    justify-content: end;

    .navigation-bar-content {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        align-content: center;
        justify-content: end;

        .desktop-navigation-menu {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            align-content: center;
            justify-content: end;
        }

        .mobile-navigation-menu {
            display: none;
        }
    }
}

@media (min-width: 280px) AND (max-width: 319.98) {
    .navigation-bar {
        .navigation-bar-content {
            .desktop-navigation-menu {
                display: none;
            }

            .mobile-navigation-menu {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: end;
            }
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media (min-width : 320px) and (max-width: 479.98px) {
    .navigation-bar {
        .navigation-bar-content {
            .desktop-navigation-menu {
                display: none;
            }

            .mobile-navigation-menu {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: end;
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 480px) and (max-width: 767.98) {
    .navigation-bar {
        .navigation-bar-content {
            .desktop-navigation-menu {
                display: none;
            }

            .mobile-navigation-menu {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: end;
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .navigation-bar {
        .navigation-bar-content {
            .desktop-navigation-menu {
                display: none;
            }

            .mobile-navigation-menu {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: end;
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width:992px) and (max-width: 1199.98px) {
    .navigation-bar {
        .navigation-bar-content {
            .desktop-navigation-menu {
                display: none;
            }

            .mobile-navigation-menu {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                align-content: center;
                justify-content: end;
            }
        }
    }
}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1024px) and (max-width:1200px) {
    .navigation-bar {
        .navigation-bar-content {
            .desktop-navigation-menu {
                display: grid;
            }

            .mobile-navigation-menu {
                display: none;
            }
        }
    }
}
