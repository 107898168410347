.ez-display {
    display: flex;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

@media only screen and (max-device-width: 768px) {
    .ez-display {
        display: block;
    }

    .text-align-left {
        text-align: unset;
    }
}
