@import '../../assets/styles/ez-responsive';

.tosPage {
    .tosBannerContainer {
        background-color: #75adde;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.04);
    }

    .ezBannerImage {
        max-width: 1513px;
        max-height: 288px;
        width: 100%;
        height: auto;
    }

    .ezMarginTop10 {
        margin-top: 10px;
    }

    .ezPad8 {
        padding: 8px;
    }

    .ezWhiteBox8 {
        padding: 8px;
        color: var(--ezClockerBlack);
        background-color: var(--ezClockerWhite);
        border-color: var(--ezClockerGray);
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;

        a {
            color: #551a8b;
        }
    }

    .ezGridContent {
        display: grid;
        padding: 4px;
        color: var(--ezContentFontColor);
        font-family: var(--ezFontFamily);
        font-size: var(--ezContentFontSize);
        font-weight: var(--ezContentFontWeight);
        font-style: var(--ezContentFontStyle);
    }

    .ezPad10 {
        padding: 10px;
    }

    .ezLeftMargin_20 {
        margin-left: 20px;
        margin-top: 1rem;
    }

    .ezMarginBottom10 {
        margin-bottom: 10px;
    }

    p {
        line-height: normal;
        margin-top: 1rem;
    }

    li {
        line-height: normal;
    }

    .ezHeading {
        margin-top: 4px;
        margin-bottom: 4px;
        color: #000000;
        font-size: 32pt;
        font-weight: bold;
        font-style: normal;
        font-family: 'Roboto', verdana, sans-serif;
    }

    h4 {
        margin-top: 4px;
        margin-bottom: 4px;
        color: #000000;
        font-size: 14pt;
        font-weight: bold;
        font-style: normal;
        font-family: 'Roboto', verdana, sans-serif;
    }

    .ezSubHeadings {
        margin-top: 4px;
        margin-bottom: 4px;
        color: #0f4777;
        font-size: 18pt;
        font-weight: bold;
        font-style: normal;
        font-family: 'Roboto', verdana, sans-serif;
    }

    padding-bottom: 1px;
}

/*------------------------------------------------------
| @Media for Regular Mobile Devices
--------------------------------------------------------*/
@media only screen and (min-width: $ezMobileMinWidth) and (max-width: $ezMobileMaxWidth) {
    .tosPage {
        .tosBannerContainer {
            height: auto;
        }

        .ezHeading {
            font-size: 18pt;
        }

        .ezSubHeadings {
            font-size: 14pt;
        }
    }
}

/*------------------------------------------------------
| @Media for Tablets
--------------------------------------------------------*/
@media only screen and (min-width: $ezTabletMinWidth) and (max-width: $ezTabletMaxWidth) {
    .tosPage {
        .ezHeading {
            font-size: 18pt;
        }

        .ezSubHeadings {
            font-size: 14pt;
        }
    }
}