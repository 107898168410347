/* Default Styling */
.header-content {
    display: grid;
    padding: 10px;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 0;
    align-content: center;
    justify-content: stretch;
    background-color: var(--ezClockerOffWhite);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
}

/* Extra small devices (phones, 600px and down) */
@media (min-width : 320px) and (max-width: 479.98px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 480px) and (max-width: 767.98) {}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width:992px) and (max-width: 1199.98px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width:1200px) {}
