.font-size-36 {
    font-size: 36px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-28 {
    font-size: 28px;
}

.font-weight-bold {
    font-weight: bold;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-12 {
    font-size: 12px
}

.verdana-font-family {
    font-family: Roboto, Verdana, sans-serif;
}
