.border-top-orange {
    border-top: solid 10px #FF9900
}

.border-left-orange {
    border-left: solid 55px #FF9900
}

.border-none {
    border: none
}

@media only screen and (max-device-width: 768px) {
    .border-left-orange {
        border-left: unset
    }


}
