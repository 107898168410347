/* Default Styling */
.navigation {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    align-content: center;
    justify-content: end;

    .navigation-menu {
        cursor: pointer;
        font-size: 12pt;
        font-weight: normal;
        list-style-type: none;
        color: var(--ezClockerBlack);
        padding: 0;

        &.menu-dropdown {
            padding: 8px;
        }

        .navigation-menu-link {
            color: var(--ezClockerBlack);
            text-decoration: none;
            padding: 8px;
            display: inline-block;
        }

        .nav-sub-menu-options-hide {
            display: none;
        }

        .nav-sub-menu-options-show {
            display: block;
            position: absolute;
            min-width: 190px;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            z-index: 1;

            .sub-menu-item {
                list-style-type: none;
                padding: 10px;
                width: 100%;
                background-color: var(--ezClockerOffWhite);
                color: var(--ezClockerBlack);
                font-weight: normal;

                .sub-menu-item-link {
                    padding: 20px;
                    width: 100%;
                    color: var(--ezClockerBlack);
                    text-decoration: none;
                }
            }

            .sub-menu-item:hover,
            .sub-menu-item:active {
                background-color: var(--ezLightGray);
            }
        }
    }

    .navigation-menu:hover,
    .navigation-menu:active {
        background-color: var(--ezLightGray);
    }
}

.navigation-hide {
    display: none;
}

/* Extra small devices (phones, 600px and down) */
@media (min-width : 320px) and (max-width: 479.98px) {
    .navigation-bar {
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-content: center;
        justify-content: right;

        .navigation {
            display: none;
        }

        .mobile-navigation {
            display: block;
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 480px) and (max-width: 767.98) {
    .navigation-bar {
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-content: center;
        justify-content: right;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .navigation-bar {
        display: grid;
        grid-template-columns: auto auto auto auto;
        align-content: center;
        justify-content: right;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width:992px) and (max-width: 1199.98px) {
    .navigation {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: auto;
        align-content: center;
        justify-content: end;

        .navigation-menu {
            padding: 8px;
            cursor: pointer;
            font-size: 16pt;
            font-weight: normal;
            list-style-type: none;

            .navigation-menu-link {
                color: var(--ezClockerBlack);
                text-decoration: none;
            }

            .nav-sub-menu-options-hide {
                display: none;
            }

            .nav-sub-menu-options-show {
                display: block;
                position: absolute;
                min-width: 190px;
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
                z-index: 1;

                .sub-menu-item {
                    list-style-type: none;
                    padding: 10px;
                    width: 100%;
                    background-color: var(--ezClockerOffWhite);
                    color: var(--ezClockerBlack);
                    font-weight: normal;

                    .sub-menu-item-link {
                        padding: 20px;
                        width: 100%;
                        color: var(--ezClockerBlack);
                        text-decoration: none;
                    }
                }

                .sub-menu-item:hover,
                .sub-menu-item:active {
                    background-color: var(--ezLightGray);
                }
            }
        }

        .navigation-menu:hover,
        .navigation-menu:active {
            background-color: var(--ezLightGray);
        }
    }
}

/* X-Large devices (laptops/desktops, 1200px and up) */
@media (min-width:1024px) and (max-width:1200px) {
    .navigation {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-template-rows: auto;
        align-content: center;
        justify-content: end;

        .navigation-menu {
            padding: 8px;
            cursor: pointer;
            font-size: 16pt;
            font-weight: normal;
            list-style-type: none;

            .navigation-menu-link {
                color: var(--ezClockerBlack);
                text-decoration: none;
            }

            .nav-sub-menu-options-hide {
                display: none;
            }

            .nav-sub-menu-options-show {
                display: block;
                position: absolute;
                min-width: 190px;
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
                z-index: 1;

                .sub-menu-item {
                    list-style-type: none;
                    padding: 10px;
                    width: 100%;
                    background-color: var(--ezClockerOffWhite);
                    color: var(--ezClockerBlack);
                    font-weight: normal;

                    .sub-menu-item-link {
                        padding: 20px;
                        width: 100%;
                        color: var(--ezClockerBlack);
                        text-decoration: none;
                    }
                }

                .sub-menu-item:hover,
                .sub-menu-item:active {
                    background-color: var(--ezLightGray);
                }
            }
        }

        .navigation-menu:hover,
        .navigation-menu:active {
            background-color: var(--ezLightGray);
        }
    }
}
